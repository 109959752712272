import { Vue } from "vue-class-component";

export const FilterContracts = async (InContracts: any[]) => {
  const FilteredContractData: any[] = [];
  const ContractTypeIds: Set<number> = new Set();
  let ContractTypeCoreProduct: Record<number, string> = {};

  for (const contract of InContracts) {
    if (
      contract.iFrameConfig !== null &&
      contract.active == true &&
      !isNaN(parseInt(contract.contractTypeId))
    ) {
      ContractTypeIds.add(contract.contractTypeId);
    }
  }

  try {
    const Response = await Vue.prototype.$axios({
      method: "POST",
      url: `customer/coreProducts`,
      data: {
        contractTypeIds: Array.from(ContractTypeIds),
      },
    });

    ContractTypeCoreProduct = Response.data;
  } catch (error) {
    console.log("Could not load core products", error);
    // use fallback: old contract type mappign
  }

  // fill missing contract types with fallback
  for (const ContractTypeId of ContractTypeIds) {
    if (!(ContractTypeId in ContractTypeCoreProduct)) {
      console.log("Fallback for contract type", ContractTypeId);
      if (
        ContractTypeId == 46 ||
        (ContractTypeId >= 1 && ContractTypeId <= 40)
      ) {
        ContractTypeCoreProduct[ContractTypeId] = "WMA";
      } else {
        ContractTypeCoreProduct[ContractTypeId] = "OTHER";
      }
    }
  }

  for (const contract of InContracts) {
    if (
      contract.iFrameConfig !== null &&
      contract.active == true &&
      !isNaN(parseInt(contract.contractTypeId)) &&
      ContractTypeCoreProduct[contract.contractTypeId] == "WMA"
    ) {
      FilteredContractData.push(contract);
    }
  }

  return FilteredContractData;
};
