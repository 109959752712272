import { FilterContracts } from "@/classes/Helper";
import {
  contract,
  customerModuleState,
  storeModuleContext,
} from "@/classes/interfaces";
import { Vue } from "vue-class-component";

export const customerModule = {
  namespaced: true,
  state: (): customerModuleState => ({
    colorValue: "",
    image: "",
    imageUrl: "",
    pdfTitleImage: "",
    pdfPage1: "",
    pdfPage2: "",
    pdfExpertText: "",
    colorOverwriteActive: 0,
    pdfEndImage: "",
    pdfLogo: "",
    mywmaLogo: "",
    contracts: [],
    originalColor: "#3f3f3f",
    nextSteps: [],
  }),

  getters: {
    activeContract: (
      state: customerModuleState,
      parameter: unknown,
      context: any,
    ): contract => {
      return state.contracts[context.view.activeContract];
    },
    activeContractId: (
      state: customerModuleState,
      parameter: unknown,
      context: any,
    ): string => {
      return state.contracts[context.view.activeContract]?.id || "0";
    },
    iframePageVisible: (
      state: customerModuleState,
      parameter: unknown,
      context: any,
    ): boolean => {
      if (typeof state.contracts[context.view.activeContract] == "object") {
        if (
          typeof state.contracts[context.view.activeContract].matchCode ==
            "string" &&
          state.contracts[context.view.activeContract].matchCode
            ?.toLowerCase()
            .indexOf("sk") == 0
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    customerId: (
      state: customerModuleState,
      parameter: unknown,
      rootState: any,
    ): string => {
      if (rootState.auth.isAdmin) {
        return rootState.view.activeAdminCustomer;
      } else {
        return (rootState.auth.user as any)[
          "https://iib-institut.de/app_metadata"
        ].customer;
      }
    },
  },
  mutations: {
    setColorValue(state: customerModuleState, value: string): void {
      state.colorValue = value;
    },
    setImage(state: customerModuleState, value: string): void {
      state.image = value;
    },
    setImageUrl(state: customerModuleState, value: string): void {
      state.imageUrl = value;
    },
    setPdfTitleImage(state: customerModuleState, value: string): void {
      state.pdfTitleImage = value;
    },
    setPdfPage1(state: customerModuleState, value: string): void {
      state.pdfPage1 = value;
    },
    setPdfPage2(state: customerModuleState, value: string): void {
      state.pdfPage2 = value;
    },
    setPdfExpertText(state: customerModuleState, value: string): void {
      state.pdfExpertText = value;
    },
    setColorOverwriteActive(state: customerModuleState, value: number): void {
      state.colorOverwriteActive = value;
    },
    setOriginalColor(state: customerModuleState, value: string): void {
      state.originalColor = value;
    },
    setPdfEndImage(state: customerModuleState, value: string): void {
      state.pdfEndImage = value;
    },
    setPdfLogo(state: customerModuleState, value: string): void {
      state.pdfLogo = value;
    },
    setMywmaLogo(state: customerModuleState, value: string): void {
      state.mywmaLogo = value;
    },
    setContracts(state: customerModuleState, value: contract[]): void {
      state.contracts = value;
    },
    setNextSteps(state: customerModuleState, value: string[]): void {
      state.nextSteps = value;
    },
  },
  actions: {
    async load(
      context: storeModuleContext<customerModuleState>,
    ): Promise<void> {
      const customerUid = (context.rootState.auth?.user as any)[
        "https://iib-institut.de/app_metadata"
      ].customer;
      const contracts = await Vue.prototype.$axios(
        `${Vue.prototype.$ENV_VARS.VUE_APP_CUSTOMER_API_DOMAIN}/1.0/customers/customers/${customerUid}/contracts`,
        {
          params: {
            token: Vue.prototype.$ENV_VARS.VUE_APP_CUSTOMER_API_TOKEN,
          },
        },
      );

      contracts.data.items = await FilterContracts(contracts.data.items);

      if (contracts.data.items.length == 0) {
        context.commit(
          "view/setError",
          "Ihrem Benutzer ist kein Vertrag zugeordnet",
          { root: true },
        );
        return;
      }

      context.commit("setContracts", contracts.data.items);
      context.dispatch("loadContractData");
    },
    async loadAdmin(
      context: storeModuleContext<customerModuleState>,
    ): Promise<boolean> {
      const customerUid = context.rootState.view?.activeAdminCustomer;
      try {
        const contracts = await Vue.prototype.$axios(
          `${Vue.prototype.$ENV_VARS.VUE_APP_CUSTOMER_API_DOMAIN}/1.0/customers/customers/${customerUid}/contracts`,
          {
            params: {
              token: Vue.prototype.$ENV_VARS.VUE_APP_CUSTOMER_API_TOKEN,
            },
          },
        );

        contracts.data.items = await FilterContracts(contracts.data.items);

        if (contracts.data.items.length == 0) {
          return false;
        }

        context.commit("setContracts", contracts.data.items);

        return true;
      } catch (error) {
        return false;
      }
    },
    async loadContractData(
      context: storeModuleContext<customerModuleState>,
    ): Promise<void> {
      context.commit("view/setLoaded", false, { root: true });

      const user = await Vue.prototype.$axios({
        url: `customer/getData/${context.rootGetters["customer/customerId"]}/${context.getters["activeContractId"]}`,
      });
      context.commit("setColorValue", user.data.colorValue);
      context.commit("setImage", user.data.image);
      context.commit("setImageUrl", user.data.imageUrl);
      context.commit("setPdfTitleImage", user.data.pdfTitleImage);
      context.commit("setPdfPage1", user.data.pdfPage1);
      context.commit("setPdfPage2", user.data.pdfPage2);
      context.commit("setPdfExpertText", user.data.pdfExpertText);
      context.commit("setColorOverwriteActive", user.data.colorOverwriteActive);
      context.commit("setPdfEndImage", user.data.pdfEndImage);
      context.commit("setPdfLogo", user.data.pdfLogo);
      context.commit("setMywmaLogo", user.data.mywmaLogo);
      context.commit("setOriginalColor", user.data.originalColor);
      context.commit("setNextSteps", user.data.nextSteps);

      context.commit("view/setLoaded", true, { root: true });
    },
    async save(
      context: storeModuleContext<customerModuleState>,
    ): Promise<void> {
      const token = await Vue.prototype.$store.dispatch("auth/getToken");

      await Vue.prototype.$axios({
        url: `customer/setData`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        data: {
          contract: context.getters["activeContractId"],
          colorValue: context.state.colorValue,
          image: context.state.image,
          imageUrl: context.state.imageUrl,
          pdfTitleImage: context.state.pdfTitleImage,
          pdfPage1: context.state.pdfPage1,
          pdfPage2: context.state.pdfPage2,
          pdfExpertText: context.state.pdfExpertText,
          colorOverwriteActive: context.state.colorOverwriteActive,
          pdfEndImage: context.state.pdfEndImage,
          pdfLogo: context.state.pdfLogo,
          mywmaLogo: context.state.mywmaLogo,
          nextSteps: context.state.nextSteps,
        },
      });
    },
    async uploadFile(
      context: storeModuleContext<customerModuleState>,
      parameters: { file: File; type: string },
    ): Promise<number> {
      const token = await Vue.prototype.$store.dispatch("auth/getToken");

      const formData = new FormData();
      formData.append("file", parameters.file);
      formData.append("contract", context.getters["activeContractId"]);

      try {
        const response = await Vue.prototype.$axios({
          url: `file/upload/${parameters.type}`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });
        return response.data;
      } catch (error: any) {
        if (error.message.indexOf("413") > -1) {
          return 413;
        }
        return 400;
      }
    },
    async deleteFile(
      context: storeModuleContext<customerModuleState>,
      parameters: { type: string },
    ): Promise<number> {
      const token = await Vue.prototype.$store.dispatch("auth/getToken");

      const formData = new FormData();
      formData.append("contract", context.getters["activeContractId"]);

      try {
        const response = await Vue.prototype.$axios({
          url: `file/delete/${parameters.type}`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });
        return response.data;
      } catch (error: any) {
        if (error.message.indexOf("413") > -1) {
          return 413;
        }
        return 400;
      }
    },
  },
};
